import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  ModalHeader,
  Row,
  Col,
  Label,
  FormFeedback
} from 'reactstrap'
import { Link } from 'react-router-dom'
import Loader from 'src/Components/Common/Loader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  CallPerson,
  CallSettings,
  useAddCallSettingsMutation,
  useUpdateCallSettingsMutation
} from 'src/services/calling'
import { useDisclosure } from 'src/utils/useDisclosure'
import { DangerAlert } from '../ErrorPage'

interface CreateCallResultProps {
  person: CallPerson
  settings?: CallSettings
  className?: string
}

export const EditSettingsCallResult: FC<CreateCallResultProps> = ({
  person,
  settings,
  className
}) => {
  const { t } = useTranslation()
  const { isOpen, open, close, toggle } = useDisclosure(false)

  const [addSettings, { isLoading: isAdding, error: addError }] =
    useAddCallSettingsMutation()
  const [updateSettings, { isLoading: isUpdating, error: updateError }] =
    useUpdateCallSettingsMutation()

  const isEditMode = !!settings

  const toggleModal = () => {
    if (isOpen) {
      validation.resetForm()
    }
    toggle()
  }

  const initialValues = {
    name: settings?.result_type || '',
    need_reason: settings?.need_reason || false,
    need_promise_amount: settings?.need_promise_amount || false,
    need_promise_date: settings?.need_promise_date || false,
    need_comment: settings?.need_comment || false,
    is_default: settings?.is_default
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('Required'))
  })

  const validation = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const payload = {
        client_type: person.name,
        result_type: values.name,
        need_reason: values.need_reason,
        need_promise_amount: values.need_promise_amount,
        need_promise_date: values.need_promise_date,
        need_comment: values.need_comment,
        is_default: values.is_default
      }
      if (isEditMode) {
        await updateSettings({ ...payload, id: settings.id })
      } else {
        await addSettings({ ...payload })
      }
      close()
    }
  })

  const checkboxFields = [
    { field: 'need_reason', label: 'Is a reason required?' },
    { field: 'need_promise_amount', label: 'Is a promise amount required?' },
    { field: 'need_promise_date', label: 'Is a promise date required?' },
    { field: 'need_comment', label: 'Is a comment required?' }
  ]

  return (
    <div className={`d-flex ${className}`}>
      {isEditMode ? (
        <Link to='#' color='link' className='link-success fs-15' onClick={open}>
          <i className='ri-edit-2-line'></i>
        </Link>
      ) : (
        <Button
          className='btn-label'
          color='primary'
          onClick={toggle}
          style={{ backgroundColor: '#405189' }}
        >
          <i className='ri-add-line label-icon align-middle fs-16 me-2'></i>
          {t('Add call result')}
        </Button>
      )}
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>
          {isEditMode ? t('Edit call result') : t('Add call result')}
        </ModalHeader>
        <ModalBody>
          <Form className='needs-validation' onSubmit={validation.handleSubmit}>
            <Row>
              <Col className='col-12'>
                <FormGroup className='mb-3'>
                  <Label htmlFor='person'>{t('Client type')}</Label>
                  <Input type='text' disabled value={person?.name}></Input>
                </FormGroup>
                <FormGroup className='mb-3'>
                  <Label htmlFor='name'>{t('Call result')}</Label>
                  <Input
                    type='text'
                    name='name'
                    id='name'
                    placeholder={t('Enter the call result')}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name}
                    invalid={
                      !!(validation.touched.name && validation.errors.name)
                    }
                  />
                  {validation.touched.name && validation.errors.name && (
                    <FormFeedback type='invalid'>
                      {validation.errors.name}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup className='mb-3'>
                  <Label>{t('Settings')}</Label>
                  {checkboxFields.map(({ field, label }) => (
                    <Label
                      key={field}
                      style={{
                        verticalAlign: 'baseline',
                        display: 'block',
                        lineHeight: '21px',
                        fontSize: '14px',
                        marginBottom: '10px'
                      }}
                      className='form-check-label'
                    >
                      <Input
                        className='form-check-input'
                        type='checkbox'
                        // @ts-ignore
                        checked={validation.values[field]}
                        name={field}
                        id={field}
                        style={{
                          width: '18px',
                          height: '18px',
                          marginRight: '15px',
                          cursor: 'pointer'
                        }}
                        onChange={validation.handleChange}
                      />
                      <span>{t(label)}</span>
                    </Label>
                  ))}
                </FormGroup>
              </Col>
            </Row>
            {!!addError && <DangerAlert error={addError} />}
            {!!updateError && <DangerAlert error={updateError} />}
            {isAdding || isUpdating ? (
              <Loader />
            ) : (
              <Button
                color='primary'
                type='submit'
                style={{ backgroundColor: '#405189' }}
                disabled={!validation.values.name}
              >
                {isEditMode ? t('Update') : t('Create')}
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}
